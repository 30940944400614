<template>
  <div class="app-container">
    <div class="content" v-loading="isFetching">
      <el-form :model="form" :rules="rules" ref="form" label-width="180px">
        <el-form-item label="SolutionID：">
          <span>{{ form.productId }}</span>
        </el-form-item>
        <el-form-item label="产品类型：">
          <span>{{ form.productType }}</span>
        </el-form-item>
        <el-form-item class="required" label="选择客户：" prop="cid">
          <el-select v-model="form.cid" filterable placeholder="请选择" value-key="cid" @change="selectGroup">
            <el-option key="" label="暂不分配" :value="null"></el-option>
            <el-option v-for="item in groupOption" :key="item.cid" :label="item.cname" :value="item.cid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择门店：" prop="shopId" v-show="noAssigned">
          <el-select :value="null" placeholder="请选择">
            <el-option key="" label="暂不分配" :value="null"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择门店：" prop="shopName" v-show="!noAssigned">
          <div class="shopBox fl" ref="shoptree" @click="setTree">
            <input type="text" readonly :value="form.shopName" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox setShops" ref="treeBox" v-show="shopShow">
            <el-input placeholder="请输入组织名称搜索" v-model="searchStore" class="input-with-select">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-tree
              node-key="shopId"
              :data="shopsTree"
              :props="defaultProps"
              :default-expanded-keys="[form.shopId === null ? '' : form.shopId]"
              ref="shop"
              :filter-node-method="filterNodeStore"
              :expand-on-click-node="false"
              :highlight-current="true"
              @node-click="shopClick"
            >
              <span slot-scope="{ node, data }">
                <span :class="{ 'tree-disabled': data.disabled }">{{ node.label }}</span>
              </span>
            </el-tree>
            <div class="dialogBtn pt20 mb15 borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="handleCancel" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button :loading="isSubmitting" type="primary" @click="submitForm('form')">提交</el-button>
          <el-button @click="resetForm('form')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import treemixin from 'src/mixins/tree'
import { getCusList } from 'src/api/legacy'
import { productDispatch } from 'src/api/product'
import { isEmpty } from 'src/utils/method'

export default {
  mixins: [treemixin],
  data() {
    const { cid, shopId, productId, productType, shopName } = this.$route.query
    return {
      id: '',
      groupOption: [],
      form: {
        productId,
        productType,
        shopId: shopId || null,
        cid: parseInt(cid) || null,
        shopName: shopName === '--' ? null : shopName,
      },
      shopRange: '',
      shopShow: false,
      noAssigned: true,
      rules: {
        cid: [
          {
            required: false,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shopName: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shopId: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      isFetching: false,
      isSubmitting: false,
    }
  },
  watch: {
    shopRange(val) {
      //触发过滤方法
      this.noAssigned = isEmpty(val)
      this.$refs.shop.filter(val)
    },
  },
  async mounted() {
    this.id = this.$route.query.id
    this.isFetching = true
    await this.getTreeData()
    await this.overviewInit()
    this.isFetching = false

    document.addEventListener('click', (e) => {
      if (
        this.$refs.treeBox &&
        !this.$refs.shoptree.contains(e.target) &&
        !this.$refs.treeBox.contains(e.target) &&
        this.shopShow
      ) {
        this.shopShow = false
      }
    })
  },
  methods: {
    submitForm(formName) {
      if (this.form.cid === null) {
        this.updateAgent()
      } else {
        this.$refs[formName].validate((valid, err) => {
          if (valid) {
            this.updateAgent()
          } else {
            console.log(err)
          }
        })
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$router.go(-1)
    },
    selectGroup(value) {
      if (value === null) {
        this.noAssigned = true
        this.form.shopName = ''
      } else {
        this.noAssigned = false
        const selectVal = this.groupOption.find((v) => value === v.cid)
        this.shopRange = selectVal?.ccode
        // 清空门店原来数据
        this.form.shopName = ''
      }
    },
    shopfilterNode(val, data) {
      //过滤组织
      if (typeof val === 'undefined') {
        val = ''
      }
      return data.prmType === 2 && data.orgcode.indexOf(val) === 0
    },
    shopClick(data) {
      //选中门店
      this.getTreeOrgcode = data
    },
    getOrgcode() {
      if (this.getTreeOrgcode) {
        this.form.shopId = this.getTreeOrgcode.shopId
        this.form.shopName = this.getTreeOrgcode.name
      }
      this.shopShow = false
      // 校验
      if (this.form.shopName) {
        $('.treeBox').next().hide()
      }
    },
    getAgent() {
      const option = this.groupOption.find((v) => {
        return this.form.cid === v.cid
      })
      this.shopRange = option?.ccode
      //设置门店树下拉框选中项
      this.$nextTick(() => {
        this.$refs.shop.setCurrentKey(this.form.shopId)
      })
    },
    getGroup() {
      //查询所有门店
      return getCusList().then((res) => {
        if (res && res.status === 0) {
          this.groupOption = res.data
          this.getAgent()
        }
      })
    },
    async updateAgent() {
      this.isSubmitting = true
      const unassigned = this.form.cid === null
      const params = {
        productId: this.form.productId,
        cid: this.form.cid,
        shopId: unassigned ? null : this.form.shopId,
      }
      try {
        const res = await productDispatch(params)
        if (res && res.status === 0) {
          if (res.data) {
            
            this.$router.push({
              name: this.$route.query.fromRoute,
              query: {
                ...this.$route.query,
                toshop: unassigned ? '0' : '1',
                from: '0', // 返回高亮显示
              },
            })
          }
        }
      } finally {
        this.isSubmitting = false
      }
    },
    async overviewInit() {
      //页面初始化
      await this.getGroup()
    },
    setTree() {
      this.shopShow = true
      const shopbox = $('.setShops .el-tree')
      const selectNode = $('.el-tree-node.is-current.is-focusable')
      setTimeout(() => {
        if (!selectNode.length) return
        shopbox.scrollTop(0)
        const t = shopbox.offset().top
        const y = selectNode.offset().top
        const interval = y - t + 'px'
        shopbox.animate(
          {
            scrollTop: interval,
          },
          0
        )
      })
    },
    handleCancel(e) {
      e.preventDefault()
      this.shopShow = false
    },
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.containerX .contentX {
  padding-left: 0;
  padding-right: 0;
}
.titletop {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 20px;
}

.titletop span {
  font-size: 14px;
  color: #5c76cc;
}

.titletop span i {
  color: #333;
}

.content {
  padding-top: 40px;
  font-size: 12px;
  padding-left: 100px;
}

.el-form-item__content .el-select .el-input .el-select__caret {
  line-height: 42px;
}
.el-select,
.shopBox input,
.el-tree > .el-tree-node {
  width: 468px;
}
.shopBox {
  margin-top: 8px;
}
.autobind .el-radio + .el-radio {
  margin-left: 100px;
}
.treeBox {
  left: 0;
  top: 40px;
}

/* 树组件搜索查询 */
.el-form-item .el-input {
  /* width: 468px */
}

.input-with-select {
  display: flex;
}
.input-with-select .el-input-group__append {
  flex: none;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
  width: auto;
}
</style>