export const notEmpty = (x) => {
  if (typeof x === 'string') {
    return x.trim() !== ''
  }
  return x !== undefined && x !== null
}

export const isEmpty = (val) => {
  if (val === null || val === undefined) {
    return true
  }
  if (typeof val === 'string' && val.trim() === '') {
    return true
  }
  return false
}
